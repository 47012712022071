import axios from "axios";
import { store } from "../../../state-management/state-mgmt";
import Utility from "../../../shared/utility";
export default {
  getDashboardData(call, userId) {
    return this.getData(`/sh/shipping_bulk_queue_dashboard?user_key=${userId}`, call);
  },
  getShippingData(call, userId, pass) {
    return this.getData(`/sh/shipping_bulk_queue?user_key=${userId}&pass=${pass}`, call);
  },
  postShippingHeader(call, obj) {
    return this.getData(`sh/shipping_bulk_queue_lot_header`, call, obj);
  },
  postShippingDetailsMember(call, obj) {
    return this.getData(`sh/shipping_bulk_queue_member_list`, call, obj);
  },
  postTrackingNum(call, obj) {
    return this.getData(`sh/shipping_bulk_queue_SO_lpn_BULK`, call, obj, true);
  },
  getUploadedFiles(call, userId) {
    return this.getData(`/usb/parts_upload_getfiles?user_key=${userId}`, call);
  },
  getEventCodes(call) {
    return this.getData(`/usb/assettracking_get_event_codes`, call);
  },
  getAssetActions(call) {
    return this.getData(`/usb/assettracking_get_event_tracking_codes`, call);
  },
  assetTrackingSearch(call, obj, showMessage) {
    return this.getData(`usb/assettracking_search_results`, call, obj, showMessage);
  },
  getAssetTrackingHeaderInfo(call, UserID, main_key) {
    return this.getData(`/usb/assettracking_header_info?UserID=${UserID}&main_key=${main_key}`, call);
  },
  getAssetTrackingEventLog(call, UserID, main_key) {
    return this.getData(`/usb/assettracking_event_log?UserID=${UserID}&main_key=${main_key}`, call);
  },
  getAssetTrackingNotes(call, userid, main_key) {
    return this.getData(`/usb/assettracking_get_tech_notes?userid=${userid}&main_key=${main_key}`, call);
  },
  getEventDetailHeader(call, UserID, main_key) {
    return this.getData(`/usb/getDetailEventsHeader?UserID=${UserID}&main_key=${main_key}`, call);
  },
  eventDetailsInfo(call, obj, showMessage) {
    return this.getData(`usb/getDetailEventsInfo`, call, obj, showMessage);
  },

  getInboundDetailsService(call, userId, main_key) {
    return this.getData(`/usb/getInBoundLogEvents?UserID=${userId}&main_key=${main_key}`, call);
  },
  getOutboundDetailsService(call, userId, main_key) {
    return this.getData(`/usb/getOutBoundLogEvents?UserID=${userId}&main_key=${main_key}`, call);
  },
  assetTrackingEventRules(call, obj, showMessage) {
    return this.getData(`usb/assettracking_check_event_rules`, call, obj, showMessage);
  },
  assetTrackingEventSubmit(call, obj, showMessage) {
    return this.getData(`usb/assettracking_event_submit`, call, obj, showMessage);
  },
  assetTrackingEventPurge(call, obj, showMessage) {
    return this.getData(`usb/assettracking_purge`, call, obj, showMessage);
  },

  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res?.data?.body?.message != undefined || res?.data?.body?.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data?.body?.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res?.data?.body?.message == null) {
      LoaderDialog.visible = false;
      store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      return JSON.parse('{"message":"NA"}');
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
};
